import React, { useState, useCallback, useEffect } from "react";
import { bool, string } from "prop-types";
import { OfferTable } from "./OfferTable";
import { TechParams } from "./utils/enums";
import { ApiMethodTypes, useApi } from "../../hooks";
import { ParametersApi, ApiStatus, BidCategory } from "../../utils/";
import { DisplayError, Spinner } from "../generic";

export const Offer = ({
  bidCategory,
  duid,
  bidSettlementDate,
  bidType,
  allowEdit,
  allowDelete,
  showAckPhysical,
  getOfferUrl,
  putOfferUrl,
  deleteOfferUrl,
  getPhyAckOfferUrl,
  offerAction
}) => {
  useEffect(
    () => {
      setTechParamsValues({}); //reset
    },
    [duid, bidType, bidCategory]
  );

  //load tech params
  const [techParamsValues, setTechParamsValues] = useState({});

  //set value
  const setValue = useCallback(({ param, value }) => {
    setTechParamsValues(prevState => ({
      ...prevState,
      [param]: value
    }));
  }, []);

  //fetch technical params
  //DISPATCH TYPE
  const {
    sendRequest: techParamsDispatchTypeSendRequest,
    data: techParamsDispatchTypeData,
    apiStatus: techParamsDispatchTypeApiStatus
    // clear: techParamsDispatchTypeClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsDispatchTypeSendRequest({
        url: ParametersApi.GetDispatchTypeUrl({
          duid,
          bidType
        })
      });
    },
    [techParamsDispatchTypeSendRequest, duid, bidType]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsDispatchTypeApiStatus === ApiStatus.Success &&
        techParamsDispatchTypeData
      ) {
        if (techParamsDispatchTypeData.length > 0 && techParamsDispatchTypeData[0])
          setValue({
            param: TechParams.DispatchType,
            value: techParamsDispatchTypeData[0]["val"]
          });
        else {
          console.error("Unable to find tech param - Dispatch Type");
        }
      }
    },
    [techParamsDispatchTypeData, techParamsDispatchTypeApiStatus, setValue]
  );

  //START TYPE
  const {
    sendRequest: techParamsStartTypeSendRequest,
    data: techParamsStartTypeData,
    apiStatus: techParamsStartTypeApiStatus
    // clear: techParamsStartTypeClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsStartTypeSendRequest({
        url: ParametersApi.GetStartTypeUrl({
          duid,
          bidType
        })
      });
    },
    [techParamsStartTypeSendRequest, duid, bidType]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsStartTypeApiStatus === ApiStatus.Success &&
        techParamsStartTypeData
      ) {
        if (techParamsStartTypeData.length > 0 && techParamsStartTypeData[0])
          setValue({
            param: TechParams.StartType,
            value: techParamsStartTypeData[0]["val"]
          });
        else {
          console.error("Unable to find tech param - Start Type");
        }
      }
    },
    [techParamsStartTypeData, techParamsStartTypeApiStatus, setValue]
  );

  //ENERGY CONSTRAINT
  const {
    sendRequest: techParamsDailyEnergyConstSendRequest,
    data: techParamsDailyEnergyConstData,
    apiStatus: techParamsDailyEnergyConstApiStatus
    // clear: techParamsDailyEnergyConstClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.ENERGY)
        techParamsDailyEnergyConstSendRequest({
          url: ParametersApi.GetIsDailyEnergyConstraintUrl({
            duid,
            bidType
          })
        });
    },
    [techParamsDailyEnergyConstSendRequest, duid, bidType, bidCategory]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsDailyEnergyConstApiStatus === ApiStatus.Success &&
        techParamsDailyEnergyConstData
      ) {
        if (techParamsDailyEnergyConstData.length > 0 && techParamsDailyEnergyConstData[0])
          setValue({
            param: TechParams.IsDailyEnergyConstraint,
            value: techParamsDailyEnergyConstData[0]["val"]
          });
        else {
          console.error("Unable to find tech param - Daily Energy Const");
        }
      }
    },
    [
      techParamsDailyEnergyConstData,
      techParamsDailyEnergyConstApiStatus,
      setValue
    ]
  );

  //MAX BID MW
  const {
    sendRequest: techParamsMaxBidAvailSendRequest,
    data: techParamsMaxBidAvailData,
    apiStatus: techParamsMaxBidAvailApiStatus
    // clear: techParamsMaxBidAvailClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsMaxBidAvailSendRequest({
        url: ParametersApi.GetMaxBidAvailUrl({
          duid,
          bidType,
          bidSettlementDate
        })
      });
    },
    [techParamsMaxBidAvailSendRequest, duid, bidType, bidSettlementDate]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxBidAvailApiStatus === ApiStatus.Success &&
        techParamsMaxBidAvailData
      ) {
        if (techParamsMaxBidAvailData.length > 0 && techParamsMaxBidAvailData[0])
          setValue({
            param: TechParams.MaxCap,
            value: techParamsMaxBidAvailData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech params - Max Bid Avail");
        }
      }
    },
    [techParamsMaxBidAvailData, techParamsMaxBidAvailApiStatus, setValue]
  );

  //MAX ROC UP
  const {
    sendRequest: techParamsMaxRocUpSendRequest,
    data: techParamsMaxRocUpData,
    apiStatus: techParamsMaxRocUpApiStatus
    // clear: techParamsMaxRocUpClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.ENERGY)
        techParamsMaxRocUpSendRequest({
          url: ParametersApi.GetMaxRocUpUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMaxRocUpSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxRocUpApiStatus === ApiStatus.Success &&
        techParamsMaxRocUpData
      ) {
        if (techParamsMaxRocUpData.length > 0 && techParamsMaxRocUpData[0])
          setValue({
            param: TechParams.MaxRocUp,
            value: techParamsMaxRocUpData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech param - Max Roc Up");
        }
      }
    },
    [techParamsMaxRocUpData, techParamsMaxRocUpApiStatus, setValue]
  );
  //MAX ROC DOWN
  const {
    sendRequest: techParamsMaxRocDownSendRequest,
    data: techParamsMaxRocDownData,
    apiStatus: techParamsMaxRocDownApiStatus
    // clear: techParamsMaxRocDownClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.ENERGY)
        techParamsMaxRocDownSendRequest({
          url: ParametersApi.GetMaxRocDownUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMaxRocDownSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxRocDownApiStatus === ApiStatus.Success &&
        techParamsMaxRocDownData
      ) {
        if (techParamsMaxRocDownData.length > 0 && techParamsMaxRocDownData[0])
          setValue({
            param: TechParams.MaxRocDown,
            value: techParamsMaxRocDownData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech params - Max Roc Down");
        }
      }
    },
    [techParamsMaxRocDownData, techParamsMaxRocDownApiStatus, setValue]
  );

  //MAX LOWER ANGLE
  const {
    sendRequest: techParamsMaxLowerAngleSendRequest,
    data: techParamsMaxLowerAngleData,
    apiStatus: techParamsMaxLowerAngleApiStatus
    // clear: techParamsMaxLowerAngleClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.FCAS)
        techParamsMaxLowerAngleSendRequest({
          url: ParametersApi.GetMaxLowerAngleUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMaxLowerAngleSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxLowerAngleApiStatus === ApiStatus.Success &&
        techParamsMaxLowerAngleData
      ) {
        if (techParamsMaxLowerAngleData.length > 0 && techParamsMaxLowerAngleData[0])
          setValue({
            param: TechParams.MaxLowerAngle,
            value: techParamsMaxLowerAngleData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech params - Max Lower Angle");
        }
      }
    },
    [techParamsMaxLowerAngleData, techParamsMaxLowerAngleApiStatus, setValue]
  );
  //MAX UPPER ANGLE
  const {
    sendRequest: techParamsMaxUpperAngleSendRequest,
    data: techParamsMaxUpperAngleData,
    apiStatus: techParamsMaxUpperAngleApiStatus
    // clear: techParamsMaxUpperAngleClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.FCAS)
        techParamsMaxUpperAngleSendRequest({
          url: ParametersApi.GetMaxUpperAngleUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMaxUpperAngleSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxUpperAngleApiStatus === ApiStatus.Success &&
        techParamsMaxUpperAngleData
      ) {
        if (techParamsMaxUpperAngleData.length > 0 && techParamsMaxUpperAngleData[0])
          setValue({
            param: TechParams.MaxUpperAngle,
            value: techParamsMaxUpperAngleData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech params - Max Upper Angle");
        }
      }
    },
    [techParamsMaxUpperAngleData, techParamsMaxUpperAngleApiStatus, setValue]
  );
  //MIN ENABLEMENT LEVEL
  const {
    sendRequest: techParamsMinEnablementLevelSendRequest,
    data: techParamsMinEnablementLevelData,
    apiStatus: techParamsMinEnablementLevelApiStatus
    // clear: techParamsMinEnablementLevelClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.FCAS)
        techParamsMinEnablementLevelSendRequest({
          url: ParametersApi.GetMinEnablementLevelUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMinEnablementLevelSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMinEnablementLevelApiStatus === ApiStatus.Success &&
        techParamsMinEnablementLevelData
      ) {
        if (techParamsMinEnablementLevelData.length > 0 && techParamsMinEnablementLevelData[0])
          setValue({
            param: TechParams.MinEnablementMin,
            value: techParamsMinEnablementLevelData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech params - Min Enablement Level");
        }
      }
    },
    [
      techParamsMinEnablementLevelData,
      techParamsMinEnablementLevelApiStatus,
      setValue
    ]
  );

  //MAX ENABLEMENT LEVEL
  const {
    sendRequest: techParamsMaxEnablementLevelSendRequest,
    data: techParamsMaxEnablementLevelData,
    apiStatus: techParamsMaxEnablementLevelApiStatus
    // clear: techParamsMaxEnablementLevelClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.FCAS)
        techParamsMaxEnablementLevelSendRequest({
          url: ParametersApi.GetMaxEnablementLevelUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [
      techParamsMaxEnablementLevelSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory
    ]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxEnablementLevelApiStatus === ApiStatus.Success &&
        techParamsMaxEnablementLevelData
      ) {
        if (techParamsMaxEnablementLevelData.length > 0 && techParamsMaxEnablementLevelData[0])
          setValue({
            param: TechParams.MaxEnablementMax,
            value: techParamsMaxEnablementLevelData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech param - Max Enablement Level");
        }
      }
    },
    [
      techParamsMaxEnablementLevelData,
      techParamsMaxEnablementLevelApiStatus,
      setValue
    ]
  );

  //MAX BID PRICE
  const {
    sendRequest: techParamsMaxBidPriceSendRequest,
    data: techParamsMaxBidPriceData,
    apiStatus: techParamsMaxBidPriceApiStatus
    // clear: techParamsMaxBidPriceClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsMaxBidPriceSendRequest({
        url: ParametersApi.GetMaxBidPriceUrl({
          duid,
          bidType,
          bidSettlementDate
        })
      });
    },
    [techParamsMaxBidPriceSendRequest, duid, bidType, bidSettlementDate]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMaxBidPriceApiStatus === ApiStatus.Success &&
        techParamsMaxBidPriceData
      ) {
        if (techParamsMaxBidPriceData.length > 0 && techParamsMaxBidPriceData[0])
          setValue({
            param: TechParams.MaxBidPrice,
            value: techParamsMaxBidPriceData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech param - Max Bid Price");
        }
      }
    },
    [techParamsMaxBidPriceData, techParamsMaxBidPriceApiStatus, setValue]
  );
  //MIN BID PRICE
  const {
    sendRequest: techParamsMinBidPriceSendRequest,
    data: techParamsMinBidPriceData,
    apiStatus: techParamsMinBidPriceApiStatus
    // clear: techParamsMinBidPriceClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsMinBidPriceSendRequest({
        url: ParametersApi.GetMinBidPriceUrl({
          duid,
          bidType,
          bidSettlementDate
        })
      });
    },
    [techParamsMinBidPriceSendRequest, duid, bidType, bidSettlementDate]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsMinBidPriceApiStatus === ApiStatus.Success &&
        techParamsMinBidPriceData
      ) {
        if (techParamsMinBidPriceData.length > 0 && techParamsMinBidPriceData[0])
          setValue({
            param: TechParams.MinBidPrice,
            value: techParamsMinBidPriceData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech param - Min Bid Price");
        }
      }
    },
    [techParamsMinBidPriceData, techParamsMinBidPriceApiStatus, setValue]
  );
  //MLF
  const {
    sendRequest: techParamsMLFSendRequest,
    data: techParamsMLFData,
    apiStatus: techParamsMLFApiStatus
    // clear: techParamsMLFClear
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      if (bidCategory === BidCategory.ENERGY)
        techParamsMLFSendRequest({
          url: ParametersApi.GetMlfUrl({
            duid,
            bidType,
            bidSettlementDate
          })
        });
    },
    [techParamsMLFSendRequest, duid, bidType, bidSettlementDate, bidCategory]
  );

  //on update technical params data
  useEffect(
    () => {
      if (techParamsMLFApiStatus === ApiStatus.Success && techParamsMLFData) {
        if (techParamsMLFData.length > 0 && techParamsMLFData[0])
          setValue({
            param: TechParams.MLF,
            value: techParamsMLFData[0]["avg_val"]
          });
        else {
          console.error("Unable to find tech param - MLF");
        }
      }
    },
    [techParamsMLFData, techParamsMLFApiStatus, setValue]
  );

  //check if all required params are loaded
  const checkAllParamsLoaded = useCallback(
    () => {
      let techParams = [
        TechParams.MaxCap,
        TechParams.DispatchType,
        TechParams.StartType,
        TechParams.MaxBidPrice,
        TechParams.MinBidPrice
      ];
      if (bidCategory === BidCategory.ENERGY)
        techParams.concat([
          TechParams.MLF,
          TechParams.IsDailyEnergyConstraint,
          TechParams.MaxRocUp,
          TechParams.MaxRocDown
        ]);
      else if (bidCategory === BidCategory.FCAS)
        techParams.concat([
          TechParams.MaxUpperAngle,
          TechParams.MaxLowerAngle,
          TechParams.MaxEnablementMax,
          TechParams.MinEnablementMin
        ]);

      let loaded = true;
      techParams.forEach(tp => {
        if (techParamsValues[tp] === undefined || techParamsValues[tp] === null)
          loaded = false;
      });
      return loaded;
    },
    [techParamsValues, bidCategory]
  );
  return (
    <>
      {(techParamsDispatchTypeApiStatus === ApiStatus.Failed ||
        techParamsStartTypeApiStatus === ApiStatus.Failed ||
        techParamsMaxBidAvailApiStatus === ApiStatus.Failed ||
        techParamsMaxBidPriceApiStatus === ApiStatus.Failed ||
        techParamsMinBidPriceApiStatus === ApiStatus.Failed ||
        techParamsMLFApiStatus === ApiStatus.Failed ||
        techParamsDailyEnergyConstApiStatus === ApiStatus.Failed ||
        techParamsMaxRocUpApiStatus === ApiStatus.Failed ||
        techParamsMaxRocDownApiStatus === ApiStatus.Failed ||
        techParamsMaxUpperAngleApiStatus === ApiStatus.Failed ||
        techParamsMaxLowerAngleApiStatus === ApiStatus.Failed ||
        techParamsMaxEnablementLevelApiStatus === ApiStatus.Failed ||
        techParamsMinEnablementLevelApiStatus === ApiStatus.Failed) && (
        <DisplayError
          title={"Error loading unit capabilities"}
          subTitle={
            "Please trying refreshing the page (Ctrl + F5). If error persists, contact support."
          }
        />
      )}
      {checkAllParamsLoaded() ? (
        <OfferTable
          duid={duid}
          bidSettlementDate={bidSettlementDate}
          bidType={bidType}
          bidCategory={bidCategory}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
          showAckPhysical={showAckPhysical}
          getOfferUrl={getOfferUrl}
          putOfferUrl={putOfferUrl}
          deleteOfferUrl={deleteOfferUrl}
          getPhyAckOfferUrl={getPhyAckOfferUrl}
          offerAction={offerAction}
          techParamsValues={techParamsValues}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

Offer.propTypes = {
  bidType: string.isRequired,
  bidCategory: string.isRequired,
  getOfferUrl: string.isRequired,
  putOfferUrl: string,
  deleteOfferUrl: string,
  getPhyAckOfferUrl: string,
  allowEdit: bool.isRequired,
  allowDelete: bool.isRequired,
  showAckPhysical: bool.isRequired,
  duid: string.isRequired,
  bidSettlementDate: string
};

Offer.defaultProps = {
  allowEdit: false,
  allowDelete: false,
  showAckPhysical: false
};
