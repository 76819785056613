import React, { useEffect, useState, useCallback, useContext } from "react";
import commaNumber from "comma-number";
import moment from "moment";
import {
  ApiStatusWrapper,
  AppContainer,
  Grid,
  InputSelect,
  DatePickerSettlementDate,
  ButtonPrimary
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridConfigDefault,
  GridFields,
  VadvisorApi
} from "../utils";
import { ThemeContext } from "../contexts";
import { Space, Form } from "antd";

const GRID_HEIGHT_OFFSET = 250;
const services = [
  "ENERGY",
  "LOWER5MIN",
  "LOWER60SEC",
  "LOWER6SEC",
  "LOWER1SEC",
  "LOWERREG",
  "RAISE5MIN",
  "RAISE60SEC",
  "RAISE6SEC",
  "RAISE1SEC",
  "RAISEREG"
];
const SourceId = {
  ManualActual: "manual_actual",
  AlgoWhatIf: "algo_whatif"
};
const sources = [
  { name: "Algo What If", key: SourceId.AlgoWhatIf },
  { name: "Manual Actual", key: SourceId.ManualActual }
];

const variables = [
  { name: "Earnings", key: DBFields.Earnings },
  { name: "Fuel Value", key: DBFields.FuelValue, columnGroupShow: "open" },
  {
    name: "Energy Revenue Impact",
    key: DBFields.EnergyRevenueImpact,
    columnGroupShow: "open"
  },
  { name: "Service Revenue", key: DBFields.Revenue, columnGroupShow: "open" },
  { name: "Volume", key: DBFields.Amount, columnGroupShow: "open" }
];

export const ReportActualManualPerformance = ({ history, match }) => {
  const theme = useContext(ThemeContext);
  const [fromDateTime, setFromDateTime] = useState();
  const [toDateTime, setToDateTime] = useState();
  const [duids, setDuids] = useState([]);
  const [filteredDuids, setFilteredDuids] = useState([]);
  // const [algos, setAlgos] = useState([]);
  const [selectedAlgoId] = useState("pd_sutil");
  const [settDate, setSettDate] = useState(
    moment()
      //.add(-3, "d") //temp set to load 3 days ago
      .format("YYYY-MM-DD")
  );

  //region DUIDs

  const {
    sendRequest: settingsDuidBidTypesSendRequest,
    data: settingsDuidBidTypesData,
    apiStatus: settingsDuidBidTypesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch data
  useEffect(
    () => {
      if (selectedAlgoId)
        settingsDuidBidTypesSendRequest({
          url: VadvisorApi.SettingsDuidBidTypesByAlgoId({
            algoId: selectedAlgoId
          })
        });
    },
    [settingsDuidBidTypesSendRequest, selectedAlgoId]
  );

  //on getting data
  useEffect(
    () => {
      if (settingsDuidBidTypesApiStatus === ApiStatus.Success) {
        let dataSet = new Set();
        settingsDuidBidTypesData.forEach(row => {
          dataSet.add(row[DBFields.Duid]);
        });

        let data = [];
        dataSet.forEach(duid => {
          data.push({
            value: duid,
            text: duid
          });
        });
        setDuids(data);
        setFilteredDuids(data.map(d => d.value)); //selected all by default on load
      }
    },
    [settingsDuidBidTypesApiStatus, settingsDuidBidTypesData]
  );

  const onChangeFilteredDuids = useCallback(values => {
    setFilteredDuids(values);
  }, []);
  //endregion

  //region Dates
  const onChangeSettDate = useCallback(date => {
    setSettDate(date);
  }, []);

  //on sett date change
  useEffect(
    () => {
      const fromDateTime = moment(settDate).format("YYYY-MM-DD 00:00:00");
      const toDateTime = moment(settDate)
        .add(1, "d")
        .format("YYYY-MM-DD 00:00:00");

      setFromDateTime(fromDateTime);
      setToDateTime(toDateTime);
    },
    [settDate]
  );

  //endregion

  //region config algos
  //
  // const {
  //   sendRequest: configAlgoSendRequest,
  //   data: configAlgoData,
  //   apiStatus: configAlgoApiStatus
  // } = useApi({ method: ApiMethodTypes.Get });
  //
  // //fetch data
  // useEffect(
  //   () => {
  //     configAlgoSendRequest({
  //       url: VadvisorApi.ConfigAlgos()
  //     });
  //   },
  //   [configAlgoSendRequest]
  // );
  //
  // //on getting data
  // useEffect(
  //   () => {
  //     if (configAlgoApiStatus === ApiStatus.Success) {
  //       let data = [];
  //       configAlgoData.forEach(row => {
  //         if (row[DBFields.IsActive])
  //           data.push({
  //             value: row[DBFields.AlgoId],
  //             text: row[DBFields.AlgoName]
  //           });
  //       });
  //       setAlgos(data);
  //       if (data.length > 0) setSelectedAlgoId(data[0].value); //select first one
  //     }
  //   },
  //   [configAlgoApiStatus, configAlgoData]
  // );

  // const onChangeAlgoId = useCallback(value => {
  //   setSelectedAlgoId(value);
  // }, []);

  //endregion

  const {
    sendRequest: reportSendRequest,
    data: reportData,
    apiStatus: reportApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch data
  const fetchReport = useCallback(
    () => {
      if (
        fromDateTime &&
        toDateTime &&
        fromDateTime !== "Invalid date" &&
        toDateTime !== "Invalid date"
      )
        reportSendRequest({
          url: VadvisorApi.ReportActualManualPerformance5m({
            fromDateTime: fromDateTime,
            toDateTime: toDateTime
          })
        });
    },
    [reportSendRequest, fromDateTime, toDateTime]
  );
  useEffect(
    () => {
      fetchReport();
    },
    [fetchReport]
  );

  const getVariableKey = ({ source, service, variableName }) =>
    `${source}_${service}_${variableName}`;

  //on getting data
  useEffect(
    () => {
      if (reportApiStatus === ApiStatus.Success) {
        let newRows = [];

        //convert array to dict
        let dataDict = {};
        reportData.forEach(row => {
          const settDate = row[DBFields.SettlementDate];
          if (!dataDict[settDate]) dataDict[settDate] = {};

          const duid = row[DBFields.Duid];
          if (!dataDict[settDate][duid]) dataDict[settDate][duid] = {};

          const service = row[DBFields.Service];
          const source = row[DBFields.SourceName];

          [
            DBFields.RRP,
            DBFields.Amount,
            DBFields.Revenue,
            DBFields.FuelValue,
            DBFields.Earnings,
            DBFields.EnergyRevenueImpact
          ].forEach(variableName => {
            const vKey = getVariableKey({ source, service, variableName });
            dataDict[settDate][duid][vKey] = row[variableName];
          });
        });
        Object.keys(dataDict).forEach(dt => {
          let row = {};
          row[GridFields.SettlementDate] = dt;
          sources.forEach(source => {
            services.forEach(service => {
              variables.forEach(variable => {
                const vKey = getVariableKey({
                  source: source.key,
                  service: service,
                  variableName: variable.key
                });
                let value = 0;
                let anyMatch = false;

                filteredDuids.forEach(duid => {
                  if (
                    dataDict[dt] &&
                    dataDict[dt][duid] &&
                    dataDict[dt][duid][vKey] !== undefined
                  ) {
                    anyMatch = true;
                    value += dataDict[dt][duid][vKey];
                  }
                });
                row[vKey] = anyMatch ? value : null;
              });
            });
          });
          newRows.push(row);
        });
        setRowData(newRows);
      }
    },
    [reportApiStatus, reportData, filteredDuids]
  );
  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    //params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.SettlementDate];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //once on mount
  useEffect(
    () => {
      resizeGrid();
    },
    [resizeGrid]
  );

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(
    () => {
      let cols = [
        {
          headerName: "Settlement Date",
          headerTooltip: "Settlement Date",
          pinned: "left",
          field: GridFields.SettlementDate,
          width: 120,
          valueFormatter: params => {
            return params.value.slice(0, 16); //first 16 characters YYYY-MM-DD HH:mm
          }
        }
      ];

      //add gain loss cols
      cols.push({
        headerName: "Specific business case effects gain/loss",
        headerTooltip:
          "Value change attributable to particular circumstances.  Note that these fields are approximations and amount to only a portion of the change in value.",
        children: [
          {
            headerName: "Service allocation stack",
            headerTooltip:
              "Value change due to switching volume between FCAS services and Energy.  This can only be true near maximum and minimum load.",
            children: [
              {
                headerName: "LowerFCAS",
                width: 120,
                cellStyle: params => {
                  if (params.value < 0)
                    return {
                      backgroundColor: theme.negativeValueBackgroundColor
                    };
                  else return { backgroundColor: "#bae7ff" };
                },
                type: "numericColumn",
                valueFormatter: formatNumber,
                valueGetter: params => {
                  const algoEnergyVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.AlgoWhatIf,
                      service: "ENERGY",
                      variableName: DBFields.Amount
                    })
                  );
                  const manualEnergyVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.ManualActual,
                      service: "ENERGY",
                      variableName: DBFields.Amount
                    })
                  );
                  if (algoEnergyVolume > manualEnergyVolume)
                    return params.getValue(
                      getVariableKey({
                        source: "diff",
                        service: "all",
                        variableName: "earnings"
                      })
                    );
                  else return 0;
                }
              },
              {
                headerName: "RaiseFCAS",
                width: 120,
                cellStyle: params => {
                  if (params.value < 0)
                    return {
                      backgroundColor: theme.negativeValueBackgroundColor
                    };
                  else return { backgroundColor: "#bae7ff" };
                },
                type: "numericColumn",
                valueFormatter: formatNumber,
                valueGetter: params => {
                  const algoEnergyVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.AlgoWhatIf,
                      service: "ENERGY",
                      variableName: DBFields.Amount
                    })
                  );
                  const manualEnergyVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.ManualActual,
                      service: "ENERGY",
                      variableName: DBFields.Amount
                    })
                  );
                  if (algoEnergyVolume < manualEnergyVolume)
                    return params.getValue(
                      getVariableKey({
                        source: "diff",
                        service: "all",
                        variableName: "earnings"
                      })
                    );
                  else return 0;
                }
              }
            ]
          },
          {
            headerName: "Regulation negative gross margin",
            headerTooltip:
              "Value change due to lowering enablement for services with a positive utilisation and with an expected negative gross margin.",
            children: [
              {
                headerName: "Lowerreg",
                width: 120,
                cellStyle: params => {
                  if (params.value < 0)
                    return {
                      backgroundColor: theme.negativeValueBackgroundColor
                    };
                  else return { backgroundColor: "#bae7ff" };
                },
                type: "numericColumn",
                valueFormatter: formatNumber,
                valueGetter: params => {
                  // const algoEnergyVolume = params.getValue(
                  //   getVariableKey({
                  //     source: SourceId.AlgoWhatIf,
                  //     service: "ENERGY",
                  //     variableName: DBFields.Amount
                  //   })
                  // );
                  // const manualEnergyVolume = params.getValue(
                  //   getVariableKey({
                  //     source: SourceId.ManualActual,
                  //     service: "ENERGY",
                  //     variableName: DBFields.Amount
                  //   })
                  // );
                  //if (algoEnergyVolume === manualEnergyVolume) {
                  const algoLowerregVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.AlgoWhatIf,
                      service: "LOWERREG",
                      variableName: DBFields.Amount
                    })
                  );
                  if (algoLowerregVolume === 0) {
                    const source1Value = params.getValue(
                      getVariableKey({
                        source: SourceId.AlgoWhatIf,
                        service: "LOWERREG",
                        variableName: DBFields.Earnings
                      })
                    );
                    const source2Value = params.getValue(
                      getVariableKey({
                        source: SourceId.ManualActual,
                        service: "LOWERREG",
                        variableName: DBFields.Earnings
                      })
                    );
                    let diff;
                    if (source1Value !== null && source2Value !== null)
                      diff = source1Value - source2Value;
                    return diff;
                  }
                  //}
                  return 0;
                }
              },
              {
                headerName: "Raisereg",
                width: 120,
                cellStyle: params => {
                  if (params.value < 0)
                    return {
                      backgroundColor: theme.negativeValueBackgroundColor
                    };
                  else return { backgroundColor: "#bae7ff" };
                },
                type: "numericColumn",
                valueFormatter: formatNumber,
                valueGetter: params => {
                  // const algoEnergyVolume = params.getValue(
                  //   getVariableKey({
                  //     source: SourceId.AlgoWhatIf,
                  //     service: "ENERGY",
                  //     variableName: DBFields.Amount
                  //   })
                  // );
                  // const manualEnergyVolume = params.getValue(
                  //   getVariableKey({
                  //     source: SourceId.ManualActual,
                  //     service: "ENERGY",
                  //     variableName: DBFields.Amount
                  //   })
                  // );
                  //if (algoEnergyVolume === manualEnergyVolume) {
                  const algoRaiseregVolume = params.getValue(
                    getVariableKey({
                      source: SourceId.AlgoWhatIf,
                      service: "RAISEREG",
                      variableName: DBFields.Amount
                    })
                  );
                  if (algoRaiseregVolume === 0) {
                    const source1Value = params.getValue(
                      getVariableKey({
                        source: SourceId.AlgoWhatIf,
                        service: "RAISEREG",
                        variableName: DBFields.Earnings
                      })
                    );
                    const source2Value = params.getValue(
                      getVariableKey({
                        source: SourceId.ManualActual,
                        service: "RAISEREG",
                        variableName: DBFields.Earnings
                      })
                    );
                    let diff;
                    if (source1Value !== null && source2Value !== null)
                      diff = source1Value - source2Value;
                    return diff;
                  }
                  // }
                  return 0;
                }
              }
            ]
          }
        ]
      });

      //add diff cols
      let diffChildren = [];
      let totalServicesChildren = [];
      variables.forEach(variable => {
        totalServicesChildren.push({
          headerName: variable.name,
          colId: getVariableKey({
            source: "diff",
            service: "all",
            variableName: variable.key
          }),
          // field: vKey,
          width: 120,
          columnGroupShow: variable.columnGroupShow,
          cellStyle: params => {
            if (params.value < 0)
              return { backgroundColor: theme.negativeValueBackgroundColor };
            else return { backgroundColor: "#ccc" };
          },
          valueGetter: params => {
            const source1Value = params.getValue(
              getVariableKey({
                source: sources[0].key,
                service: "all",
                variableName: variable.key
              })
            );
            const source2Value = params.getValue(
              getVariableKey({
                source: sources[1].key,
                service: "all",
                variableName: variable.key
              })
            );
            let diff;
            if (source1Value !== null && source2Value !== null)
              diff = source1Value - source2Value;
            return diff;
          },
          type: "numericColumn",
          valueFormatter: formatNumber
        });
      });
      services.forEach(service => {
        let serviceChildren = [];
        variables.forEach(variable => {
          serviceChildren.push({
            headerName: variable.name,
            type: "numericColumn",
            width: 120,
            valueFormatter: formatNumber,
            cellStyle: params => {
              if (params.value < 0)
                return { backgroundColor: theme.negativeValueBackgroundColor };
              else return { backgroundColor: "#eee" };
            },
            columnGroupShow: variable.columnGroupShow,

            valueGetter: params => {
              const source1Value =
                params.data[
                  getVariableKey({
                    source: sources[0].key,
                    service,
                    variableName: variable.key
                  })
                ];
              const source2Value =
                params.data[
                  getVariableKey({
                    source: sources[1].key,
                    service,
                    variableName: variable.key
                  })
                ];
              let diff;
              if (source1Value !== null && source2Value !== null)
                diff = source1Value - source2Value;
              return diff;
            }
          });
        });

        diffChildren.push({
          headerName: service,
          children: serviceChildren,
          columnGroupShow: "open"
        });
      });
      cols.push({
        headerName: `Difference (${sources[0].name} - ${sources[1].name})`,
        children: [
          { headerName: "All Services", children: totalServicesChildren },
          ...diffChildren
        ]
      });

      //add each source cols
      sources.forEach(source => {
        let sourceChildren = [];
        let totalServicesChildren = [];

        variables.forEach(variable => {
          const vKey = getVariableKey({
            source: source.key,
            service: "all",
            variableName: variable.key
          });
          totalServicesChildren.push({
            headerName: variable.name,
            colId: vKey,
            width: 120,
            columnGroupShow: variable.columnGroupShow,
            cellStyle: params => {
              if (params.value < 0)
                return { backgroundColor: theme.negativeValueBackgroundColor };
              else return { backgroundColor: "#ddd" };
            },
            valueGetter: params => {
              let total = 0;
              let anyMatch = false;
              services.forEach(service => {
                const value =
                  params.data[
                    getVariableKey({
                      source: source.key,
                      service: service,
                      variableName: variable.key
                    })
                  ];
                if (value !== undefined) {
                  anyMatch = true;
                  total += value;
                }
              });
              return anyMatch ? total : null;
            },
            type: "numericColumn",
            valueFormatter: formatNumber
          });
        });

        services.forEach(service => {
          let serviceChildren = [];
          variables.forEach(variable => {
            const vKey = getVariableKey({
              source: source.key,
              service,
              variableName: variable.key
            });
            serviceChildren.push({
              headerName: variable.name,
              field: vKey,
              width: 120,
              columnGroupShow: variable.columnGroupShow,
              cellStyle: params => {
                if (params.value < 0)
                  return {
                    backgroundColor: theme.negativeValueBackgroundColor
                  };
                else return null;
              },
              type: "numericColumn",
              valueFormatter: formatNumber
            });
          });

          sourceChildren.push({
            headerName: service,
            children: serviceChildren,
            columnGroupShow: "open"
          });
        });
        cols.push({
          headerName: source.name,

          children: [
            { headerName: "All Services", children: totalServicesChildren },
            ...sourceChildren
          ]
        });
      });
      setColumnDefs(cols);
    },
    [theme]
  );

  const formatNumber = params => {
    return params.value !== undefined && params.value !== null
      ? commaNumber(parseFloat(params.value).toFixed(0))
      : params.value;
  };
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[settingsDuidBidTypesApiStatus]}>
        <Space size={"large"}>
          {/*<Form.Item label="Algo">*/}
          {/*  <InputSelect*/}
          {/*    value={selectedAlgoId}*/}
          {/*    options={algos}*/}
          {/*    style={{ width: "150px" }}*/}
          {/*    onChange={onChangeAlgoId}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
          <Form.Item label="Date">
            <DatePickerSettlementDate
              value={settDate}
              onChange={onChangeSettDate}
            />
          </Form.Item>

          <Form.Item
            label="DUIDs"
            validateStatus={
              !filteredDuids || filteredDuids.length < 1 ? "error" : null
            }
            help={
              !filteredDuids || filteredDuids.length < 1
                ? "Please select a DUID"
                : null
            }
          >
            <InputSelect
              style={{ width: "450px" }}
              value={filteredDuids}
              mode={"multiple"}
              options={duids}
              onChange={onChangeFilteredDuids}
            />
          </Form.Item>
          <Form.Item>
            <ButtonPrimary onClick={fetchReport}>Refresh</ButtonPrimary>
          </Form.Item>
        </Space>
      </ApiStatusWrapper>
      <ApiStatusWrapper
        statuses={[reportApiStatus]}
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
      />
      <Grid
        gridHeight={gridHeight}
        defaultColDef={GridConfigDefault}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultExportParams={{
          allColumns: true
        }}
        enableRangeSelection={true}
        // groupDefaultExpanded={-1}
        // suppressCellSelection={true}
        // suppressContextMenu={true}
        // suppressRowClickSelection={true}
        rowSelection={"multiple"}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        getRowNodeId={getRowNodeId}
        // frameworkComponents={{
        //   GridCellLink: GridCellLink,
        //   GridCellTimeAgoLink: GridCellTimeAgoLink
        // }}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true
              }
            }
          ]
        }}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agAggregationComponent",
              statusPanelParams: {
                // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                aggFuncs: ["sum"]
              }
              // align: "left"
            },
            {
              statusPanel: "agTotalRowCountComponent",
              align: "left"
            }
            // {
            //   statusPanel: "agFilteredRowCountComponent",
            //   align: "left"
            // }
          ]
        }}
      />
    </AppContainer>
  );
};
